import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import '../../node_modules/bootstrap/dist/css/bootstrap.css'
import Navi from '../components/navi'
import './layout.css'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Webseite des Marburger Konvents' },
            { name: 'keywords', content: 'Dachverband, Turnerschaften, Studentenverbindung' },
          ]}
        />
        <Navi />
        <div role="main" className="container">
          {children}
        </div>
        <footer className="container mt-5">
          <p>
            <Link to="/datenschutz">Datenschutzerklärung</Link>&nbsp;&middot;&nbsp;
            <a href="https://de.wikipedia.org/wiki/Marburger_Konvent">Wikipedia Artikel</a>
          </p>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
